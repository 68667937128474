const accordions = document.querySelectorAll(".accordion");

accordions.forEach((accordion) => {
  accordion
    .querySelector(".accordion__header")
    .addEventListener("click", () => {
      accordion.classList.toggle("accordion--active");
      let body = accordion.querySelector(".accordion__body");
      if (body.style.maxHeight) {
        body.style.maxHeight = null;
      } else {
        body.style.maxHeight = body.scrollHeight + "px";
      }
    });
});
