import { checkCookie, setCookie, getCookie } from "./cookieHelper";

const maps = document.querySelectorAll(".optin-map");

maps.forEach((map) => {
  const cookieName = map.getAttribute("data-cookie-name");
  const mapElm = map.querySelector(".optin-map__map");
  const mapSrc = mapElm.getAttribute("data-map-src");
  const overlay = map.querySelector(".optin-map__optin-overlay");

  //Check if the user has already consented
  if (checkCookie(cookieName) && getCookie(cookieName) === "true") {
    //User has already consented => show map without opt-in
    if (mapSrc !== "") {
      mapElm.setAttribute("src", mapSrc);
      overlay.style.display = "none";
    } else {
      return;
    }
  }

  map.querySelector(".btn__accept").addEventListener("click", () => {
    //Load map and hide overlay
    if (mapSrc !== "") {
      mapElm.setAttribute("src", mapSrc);
      overlay.style.display = "none";
      setCookie(cookieName, true, 30);
    } else {
      return;
    }
  });
});
