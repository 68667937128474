window.cookieconsent.initialise({
  palette: {
    popup: {
      background: "var(--highlight-color)",
      text: "#ffffff",
    },
    button: {
      background: "#ffffff",
      text: "var(--highlight-color)",
    },
  },
  theme: "classic",
  position: "bottom-left",
  content: {
    message:
      "Diese Website verwendet Cookies, welche für deren Funktion erforderlich sind.",
    dismiss: "Einverstanden",
    link: "Weitere Informationen",
    href: "/impressum-datenschutz",
  },
});
