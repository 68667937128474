const mainNav = document.querySelector(".sticky-nav");
const navToggle = document.querySelector(".stick-nav__nav-toggle");

navToggle.addEventListener("click", () => {
  if (mainNav.classList.contains("sticky-nav--nav-open")) {
    mainNav.classList.remove("sticky-nav--nav-open");
  } else {
    mainNav.classList.add("sticky-nav--nav-open");
  }
});
