//Set the cookie for the banner
const setCookie = (cookieName, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cookieName + "=" + cvalue + ";" + expires + ";path=/";
};

//Get a cookie from the application's cookies
const getCookie = (cookieName) => {
  var name = cookieName + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

//Check for a cookie
const checkCookie = (cookieName) => {
  var cookie = getCookie(cookieName);
  if (cookie === "") {
    return false;
  } else {
    return true;
  }
};

//Delete a cookie
const deleteCookie = (cookieName) => {
  if (getCookie(cookieName) !== "") {
    document.cookie =
      cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
};

export { setCookie, getCookie, checkCookie, deleteCookie };
