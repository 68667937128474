import {
  setCookie,
  getCookie,
  checkCookie,
  deleteCookie,
} from "./cookieHelper";

const elms = document.querySelectorAll(".fm-privacy-switch");

elms.forEach((elm) => {
  const checkbox = elm.querySelector(".fm-privacy-switch__checkbox");
  const cookieName = elm.getAttribute("data-cookie-name");

  //If there is no cookie name or the cookie name is a empty string return
  if (cookieName === undefined || cookieName === "") {
    return;
  }

  //Check for a cookie and its value
  //Check the checkbox if the cookie exists and has a value of "true"
  if (checkCookie(cookieName) === true && getCookie(cookieName) === "true") {
    checkbox.checked = true;
  }

  //Listen to user event on the checkbox
  checkbox.addEventListener("change", (e) => {
    if (e.target.checked === true) {
      //User accepts cookies
      setCookie(cookieName, true, 30);
    } else {
      //User not accepts cookies
      deleteCookie(cookieName);
    }
  });
});
